/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowJalonText from '../CaseWorkflowJalonText'

/* Type imports ------------------------------------------------------------- */
import type { JalonRendezVousMetre } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowMeasureRdvJalonCardProps {
  jalon: JalonRendezVousMetre;
}

const CaseWorkflowMeasureRdvJalonCard: React.FC<CaseWorkflowMeasureRdvJalonCardProps> = ({ jalon }) => {

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          RDV métré
        </CaseWorkflowStyledComponents.Title>
        <div />
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.dateRendezVous || ''}
          state={jalon.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowMeasureRdvJalonCard
