/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetDisasterNatureListQuery,
  usePostMediaFilesMutation,
  usePostNewMissionMutation,
  useGetPrestationTypeListQuery,
} from 'store/api'
import { handleNumberVerification } from 'helpers/numberUtils'
import {
  isApiError,
  isApiResponse,
} from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CircularProgress,
  Menu,
  MenuItem,
  Checkbox,
} from '@mui/material'
import {
  MoreVertRounded,
  TaskRounded,
} from '@mui/icons-material'
import {
  Select,
  TextField,
} from 'formik-mui'
import { toast } from 'react-toastify'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import PriceField from 'components/FieldWithInputAdornment/PriceField'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import AttachmentButton from 'components/AttachmentButton/AttachmentButton'
import AddressAutocomplete from 'components/FieldWithInputAdornment/AddressAutocomplete'
import LongButton from 'components/LongButton/LongButton'

/* Type imports ------------------------------------------------------------- */
import {
  Field,
  type FormikContextType,
  type FormikHelpers,
} from 'formik'
import type { Feature } from 'geojson'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type {
  CreationDossierSinistreRequest,
  CreationDossierRequest,
  CreationDossierPersonneRequest,
  CreationDossierDommageRequest,
  RefDossier,
  MediathequeDossierCreatePayload,
} from 'API/__generated__/Api'
import { TypePrestation } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
interface NewMissionRequest extends CreationDossierRequest {
  takeInsuredAddress: boolean;
}

const newMissionSchema = Yup.object().shape<Shape<NewMissionRequest>>({
  takeInsuredAddress: Yup.boolean(),
  typeDePrestation: Yup.mixed<TypePrestation>().required('Le type de prestation est obligatoire'),
  numeroContrat: Yup.string().required('Le numéro de contrat est obligatoire'),
  montantFranchise: Yup.number(),
  urgence: Yup.boolean(),
  messagePrivePrestataire: Yup.string(),
  assure: Yup.object().shape<Shape<CreationDossierPersonneRequest>>({
    nom: Yup.string().required('Le nom est obligatoire'),
    codePostal: Yup.string().required('Le code postal est obligatoire'),
    ville: Yup.string().required('La ville est obligatoire'),
    telephoneMobile: Yup.string().required('Le mobile est obligatoire').test(
      'personnal-phone',
      'Le mobile est invalide',
      (item = '') => (/^(06|07)/).test(item) && item.replaceAll(' ', '').length === 10,
    ),
    telephoneFixe: Yup.string().test(
      'home-phone',
      'Le fixe est invalide',
      (item = '') => !item || ((/^(01|02|03|04|05|08|09)/).test(item) && item.replaceAll(' ', '').length === 10),
    ),
  }).required(),
  sinistre: Yup.object().shape<Shape<CreationDossierSinistreRequest>>({
    numero: Yup.string().required('Le numéro de sinistre est obligatoire'),
    nature: Yup.string().required('La nature est obligatoire'),
    date: Yup.string().nullable().test(
      'is-past-date-echeance',
      "La date ne peut pas être supérieure à aujourd'hui",
      (value) => !value || new Date() >= new Date(value || ''),
    ),
    codePostal: Yup.string().required('Le code postal est obligatoire'),
    ville: Yup.string().required('La ville est obligatoire'),
  }).required(),
  dommage: Yup.object().shape<Shape<CreationDossierDommageRequest>>({
    montant: Yup.number(),
    commentaire: Yup.string().required('La description des dommages est obligatoire'),
  }).required(),
}).required()

type NewMissionForm = FormikContextType<NewMissionRequest>

/* Styled components -------------------------------------------------------- */
const CardContentContainer = styled.div`
  padding: 0px 20px 20px;
`

const BoldTitle = styled(FormBoldTitle)`
  font-size: 1.15rem;
  margin-bottom: 15px;
`

const CardContainer = styled(Card)`
  margin-top: 20px;
`

const InfoContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
`

const FileInfoContainer = styled(InfoContainer)`
  flex-wrap: wrap;
`

const FieldContainer = styled.div`
  width: 100%;
`

const AddressSeparator = styled.div`
  height: 10px;
`

const PriceFieldContainer = styled(PriceField)`
  .MuiInputBase-root {
    width: 50%;
  }
`

const FileContainer = styled.div`
  display: flex;
  border: 1px solid lightgray;
  border-radius: 4px;
  min-width: 150px;
  height: 40px;
  align-items: center;
`

const FileNameContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  margin-right: auto;
  margin-left: 5px;
`

const EditButton = styled(Button)`
  min-width: 30px;
  width: 30px;
`

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: -10px;
`

const HalfDivContainer = styled.div`
  width: 50%;

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
  }
`

const DuoButtonContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column-reverse;
  }
`

/* Component declaration ---------------------------------------------------- */
interface NewMissionPageProps {}

const NewMissionPage: React.FC<NewMissionPageProps> = () => {
  const navigate = useNavigate()
  const [ files, setFiles ] = useState<File[]>([])
  const [ fileIndex, setFileIndex ] = useState<number>(0)
  const [ displayFranchise, setDisplayFranchise ] = useState<boolean>(true)
  const [ anchorMenu, setAnchorMenu ] = useState<null | HTMLButtonElement>(null)

  const {
    currentData: disasterNatureList = [],
    isFetching: isFetchingDisasterNatureList,
  } = useGetDisasterNatureListQuery()
  const {
    currentData: prestationTypeList = [],
    isFetching: isFetchingPrestationTypeList,
  } = useGetPrestationTypeListQuery()
  const [
    submitNewMission,
  ] = usePostNewMissionMutation()
  const [
    submitNewDocuments,
  ] = usePostMediaFilesMutation()

  const defaultPerson: CreationDossierPersonneRequest = {
    nom: '',
    prenom: '',
    telephoneMobile: '',
    telephoneFixe: '',
    adresse1: '',
    adresse2: '',
    adresse3: '',
    codePostal: '',
    ville: '',
  }

  const onSubmit = async (values: NewMissionRequest, { setSubmitting, resetForm }: FormikHelpers<NewMissionRequest>): Promise<void> => {
    const data = structuredClone(values)

    if (!displayFranchise) {
      data.montantFranchise = null
    }

    const response = await submitNewMission(data)

    if (isApiResponse<RefDossier>(response)) {
      toast.success('La nouvelle mission a bien été envoyée.')
      const data: MediathequeDossierCreatePayload = { elementMediathequeRequest: []}

      if (files.length > 0) {
        files.forEach((file) => {
          if (file !== undefined) {
            data.elementMediathequeRequest?.push({
              fichier: file,
              nom: file.name,
            })
          }
        })

        submitNewDocuments({
          caseId: response.data.refComplete,
          data: data,
        }).then((response) => {
          if (!isApiError(response)) {
            toast.success('Vos fichiers ont bien été envoyés.')
            setFiles([])
          } else {
            toast.error("Une erreur est survenue lors de l'envoi des pièces jointes.")
          }
        }).catch(console.error)
      }
      resetForm()
    } else {
      toast.error("Une erreur est survenue lors de l'envoi de la mission.")
    }
    setSubmitting(false)
  }

  const formikForm: NewMissionForm = useForm<NewMissionRequest>(
    {
      initialValues: {
        takeInsuredAddress: false,
        numeroContrat: '',
        messagePrivePrestataire: '',
        dommage: {
          montant: 0,
          commentaire: '',
        },
        urgence: false,
        assure: defaultPerson,
        sinistre: {
          numero: '',
          nature: '',
          date: '',
          nom: '',
          adresse1: '',
          adresse2: '',
          codePostal: '',
          ville: '',
        },
        typeDePrestation: TypePrestation.ReparationEnNature,
        montantFranchise: 0,
      },
      onSubmit: onSubmit,
      validationSchema: newMissionSchema,
    },
  )

  useEffect(() => {
    const data = structuredClone(formikForm.values)
    if (formikForm.values.takeInsuredAddress) {
      data.sinistre = {
        ...data.sinistre,
        adresse1: data.assure.adresse1,
        adresse2: data.assure.adresse2,
        codePostal: data.assure.codePostal,
        ville: data.assure.ville,
      }

      formikForm.setFieldValue('sinistre', data.sinistre)
    }
  }, [ formikForm.values.takeInsuredAddress ])

  const handleOnFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files?.length !== undefined && e.target.files.length > 0) {
      setFiles([ ...files, ...Object.values(e.target.files ?? {}) ])
    }
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, index: number): void => {
    setAnchorMenu(event.currentTarget)
    setFileIndex(index)
  }

  const handleMenuClose = () => {
    setAnchorMenu(null)
  }

  const onDeleteFile = () => {
    const newFiles = [ ...files ]
    newFiles.splice(fileIndex, 1)
    setFiles(newFiles)
    handleMenuClose()
  }

  const onAddressChange = (newAddress: Feature, type: 'assure' | 'sinistre') => {
    formikForm.setValues({
      ...formikForm.values,
      [type]: {
        ...formikForm.values[type],
        adresse1: newAddress.properties?.name as string,
        codePostal: newAddress.properties?.postcode as string,
        ville: newAddress.properties?.city as string,
      },
    })
  }

  const assureAddressErrorProps = () => {
    const addressTouched = (formikForm.touched.assure as unknown as CreationDossierPersonneRequest)?.adresse1
    const addressError = (formikForm.errors.assure as unknown as CreationDossierPersonneRequest)?.adresse1
    return ({
      error: addressTouched && addressError !== undefined,
      helperText: addressTouched && addressError !== undefined ? addressError : undefined,
    })
  }

  const disasterAddressErrorProps = () => {
    const addressTouched = (formikForm.touched.assure as unknown as CreationDossierPersonneRequest)?.adresse1
    const addressError = (formikForm.errors.assure as unknown as CreationDossierPersonneRequest)?.adresse1
    return ({
      error: addressTouched && addressError !== undefined,
      helperText: addressTouched && addressError !== undefined ? addressError : undefined,
    })
  }

  const typeDePrestationOptions: SegmentedButtonOption<TypePrestation>[] = prestationTypeList.map((value) => ({ value: value.code, label: value.libelle }))
  const booleanOptions: SegmentedButtonOption<boolean>[] = [ { value: true, label: 'Oui' }, { value: false, label: 'Non' } ]

  return (
    <Form form={formikForm}>
      <LargeTitle>
        Nouveau missionnement
        <DuoButtonContainer>
          <LongButton
            variant="outlined"
            onClick={() => navigate('/dossiers')}
          >
            Annuler
          </LongButton>
          <LongButton
            variant="contained"
            type="submit"
          >
            Envoyer
          </LongButton>
        </DuoButtonContainer>
      </LargeTitle>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Identification de la mission
          </BoldTitle>
          <InfoContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Type de prestation
              </FormBoldTitle>
              <SegmentedButtons
                options={typeDePrestationOptions}
                selectedOption={formikForm.values.typeDePrestation}
                setSelectedOption={(newVal) => formikForm.setFieldValue('typeDePrestation', newVal)}
                smaller
              />
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Mission urgente
              </FormBoldTitle>
              <SegmentedButtons
                options={booleanOptions}
                selectedOption={formikForm.values.urgence}
                setSelectedOption={(newVal) => formikForm.setFieldValue('urgence', newVal)}
                smaller
              />
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                N° sinistre
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="N° sinistre"
                name="sinistre.numero"
                size="small"
              />
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                N° contrat
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="N° contrat"
                name="numeroContrat"
                size="small"
              />
            </FieldContainer>
          </InfoContainer>
        </CardContentContainer>
      </CardContainer>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Identification de l'assuré
          </BoldTitle>
          <InfoContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Nom
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Nom"
                name="assure.nom"
                size="small"
              />
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle smaller>
                Prénom
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Prénom"
                name="assure.prenom"
                size="small"
              />
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Tel mobile
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Tel mobile"
                name="assure.telephoneMobile"
                size="small"
              />
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle smaller>
                Tel fixe
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Tel fixe"
                name="assure.telephoneFixe"
                size="small"
              />
            </FieldContainer>
          </InfoContainer>
          <FieldContainer>
            <FormBoldTitle smaller>
              Adresse
            </FormBoldTitle>
            <AddressAutocomplete
              placeholder="Adresse"
              value={formikForm.values.assure.adresse1 || ''}
              onValueChange={(newValue: string) => formikForm.setFieldValue('assure.adresse1', newValue)}
              onAddressChange={(newValue) => onAddressChange(newValue, 'assure')}
              size="small"
              {...assureAddressErrorProps}
            />
            <AddressSeparator />
            <Field
              component={TextField}
              placeholder="Adresse"
              name="assure.adresse2"
              size="small"
            />
            <AddressSeparator />
            <Field
              component={TextField}
              placeholder="Adresse"
              name="assure.adresse3"
              size="small"
            />
          </FieldContainer>
          <InfoContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Code postal
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Code postal"
                name="assure.codePostal"
                size="small"
              />
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Ville
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Ville"
                name="assure.ville"
                size="small"
              />
            </FieldContainer>
          </InfoContainer>
        </CardContentContainer>
      </CardContainer>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Identification du sinistre
            {(isFetchingDisasterNatureList || isFetchingPrestationTypeList) && <CircularProgress size={24} />}
          </BoldTitle>
          <InfoContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Nature sinistre
              </FormBoldTitle>
              <Field
                component={Select}
                name="sinistre.nature"
                displayEmpty
                disabled={isFetchingDisasterNatureList}
                size="small"
              >
                <MenuItem value="">
                  Sélectionner
                </MenuItem>
                {
                  disasterNatureList.map((value, index) => (
                    <MenuItem
                      value={value.code || ''}
                      key={`${value.code}-${index}`}
                    >
                      {value.libelle}
                    </MenuItem>
                  ))
                }
              </Field>
            </FieldContainer>
            <FieldContainer>
              <FormBoldTitle smaller>
                Date
              </FormBoldTitle>
              <FormikDatePicker
                name="sinistre.date"
                size="small"
              />
            </FieldContainer>
          </InfoContainer>
          <FormBoldTitle smaller>
            Adresse
          </FormBoldTitle>
          <CheckboxContainer>
            <Checkbox
              onChange={() => formikForm.setFieldValue('takeInsuredAddress', !formikForm.values.takeInsuredAddress)}
              checked={formikForm.values.takeInsuredAddress}
            />
            L'adresse du sinistre est l'adresse de l'assuré
          </CheckboxContainer>
          {
            !formikForm.values.takeInsuredAddress &&
              <>
                <AddressAutocomplete
                  placeholder="Adresse"
                  value={formikForm.values.sinistre.adresse1 || ''}
                  onValueChange={(newValue: string) => formikForm.setFieldValue('sinistre.adresse1', newValue)}
                  onAddressChange={(newValue) => onAddressChange(newValue, 'sinistre')}
                  size="small"
                  {...disasterAddressErrorProps}
                />
                <AddressSeparator />
                <Field
                  component={TextField}
                  placeholder="Adresse"
                  name="sinistre.adresse2"
                  size="small"
                />
                <InfoContainer>
                  <FieldContainer>
                    <FormBoldTitle
                      smaller
                      required
                    >
                      Code postal
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Code postal"
                      name="sinistre.codePostal"
                      size="small"
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <FormBoldTitle
                      smaller
                      required
                    >
                      Ville
                    </FormBoldTitle>
                    <Field
                      component={TextField}
                      placeholder="Ville"
                      name="sinistre.ville"
                      size="small"
                    />
                  </FieldContainer>
                </InfoContainer>
              </>
          }
        </CardContentContainer>
      </CardContainer>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Description des dommages
          </BoldTitle>
          <FormBoldTitle smaller>
            Montant
          </FormBoldTitle>
          <PriceFieldContainer
            name="dommage.montant"
            size="small"
            value={formikForm.values.dommage.montant}
            onChange={(e) => formikForm.setFieldValue('dommage.montant', handleNumberVerification(e.target.value, 2))}
          />
          <InfoContainer>
            <FieldContainer>
              <FormBoldTitle
                smaller
                required
              >
                Description
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Description"
                name="dommage.commentaire"
                rows={3}
                size="small"
                multiline
              />
            </FieldContainer>
          </InfoContainer>
        </CardContentContainer>
      </CardContainer>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Franchise
          </BoldTitle>
          <HalfDivContainer>
            <SegmentedButtons
              options={booleanOptions}
              selectedOption={displayFranchise}
              setSelectedOption={(newVal) => setDisplayFranchise(newVal)}
              smaller
            />
          </HalfDivContainer>
          {
            displayFranchise &&
              <>
                <FormBoldTitle smaller>
                  Montant
                </FormBoldTitle>
                <PriceFieldContainer
                  name="montantFranchise"
                  size="small"
                  value={formikForm.values.montantFranchise}
                  onChange={(e) => formikForm.setFieldValue('montantFranchise', handleNumberVerification(e.target.value, 2))}
                />
              </>
          }
        </CardContentContainer>
      </CardContainer>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Pièces jointes
            <AttachmentButton
              name="new-mission"
              onChange={handleOnFileChange}
              accept=".pdf,image/*"
            >
              <Button
                variant="outlined"
                component="span"
              >
                Ajouter une pièce jointe
              </Button>
            </AttachmentButton>
          </BoldTitle>
          <FileInfoContainer>
            {
              files.map((file, index) => (
                <FileContainer key={`${file.name}-${index}`}>
                  <FileNameContainer>
                    <TaskRounded color="primary" />
                    {file.name}
                  </FileNameContainer>
                  <EditButton
                    variant="text"
                    onClick={(e) => handleMenuClick(e, index)}
                  >
                    <MoreVertRounded color="primary" />
                  </EditButton>
                  <Menu
                    anchorEl={anchorMenu}
                    open={Boolean(anchorMenu)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={onDeleteFile}>
                      Supprimer
                    </MenuItem>
                  </Menu>
                </FileContainer>
              ))
            }
          </FileInfoContainer>
        </CardContentContainer>
      </CardContainer>
      <CardContainer>
        <CardContentContainer>
          <BoldTitle>
            Message privé à SYMABAT
          </BoldTitle>
          <InfoContainer>
            <FieldContainer>
              <FormBoldTitle smaller>
                Message
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Message"
                name="messagePrivePrestataire"
                rows={3}
                size="small"
                multiline
              />
            </FieldContainer>
          </InfoContainer>
        </CardContentContainer>
      </CardContainer>
      <Footer />
    </Form>
  )
}

export default NewMissionPage
