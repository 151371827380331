/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { getCleanFilename } from 'helpers/mediaUtils'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowDot from '../CaseWorkflowDot'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowDownloadButton from '../CaseWorflowButtons/CaseWorkflowDownloadButton'
import CaseWorkflowJalonText from '../CaseWorkflowJalonText'

/* Type imports ------------------------------------------------------------- */
import type { JalonPostIntervention } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowPostInterventionJalonCardProps {
  jalon: JalonPostIntervention;
}

const CaseWorkflowPostInterventionJalonCard: React.FC<CaseWorkflowPostInterventionJalonCardProps> = ({ jalon }) => {

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          Post Intervention
        </CaseWorkflowStyledComponents.Title>
        <div />
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.dateValidation || ''}
          state={jalon.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonPhotoAvantTravaux.etat}
            small
          />
          Photos avant travaux
        </CaseWorkflowStyledComponents.Line>
        {
          jalon.sousJalonPhotoAvantTravaux.photoAvantTravaux.length > 0 ?
            <CaseWorkflowStyledComponents.PicturesContainer>
              {
                jalon.sousJalonPhotoAvantTravaux.photoAvantTravaux.map((file, index) => (
                  <CaseWorkflowDownloadButton
                    key={`${file.url}-${index}`}
                    name={getCleanFilename(file.libelle || '', file.fileName || '')}
                    url={file.url || ''}
                    isPhoto
                  />
                ))
              }
            </CaseWorkflowStyledComponents.PicturesContainer> :
            <div />
        }
        <CaseWorkflowJalonText
          jalon="sousJalonPhotoAvantTravaux"
          date={jalon.sousJalonPhotoAvantTravaux.dateValidation || ''}
          state={jalon.sousJalonPhotoAvantTravaux.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonPhotoApresTravaux.etat}
            small
          />
          Photos après travaux
        </CaseWorkflowStyledComponents.Line>
        {
          jalon.sousJalonPhotoApresTravaux.photoApresTravaux.length > 0 ?
            <CaseWorkflowStyledComponents.PicturesContainer>
              {
                jalon.sousJalonPhotoApresTravaux.photoApresTravaux.map((file, index) => (
                  <CaseWorkflowDownloadButton
                    key={`${file.url}-${index}`}
                    name={getCleanFilename(file.libelle || '', file.fileName || '')}
                    url={file.url || ''}
                    isPhoto
                  />
                ))
              }
            </CaseWorkflowStyledComponents.PicturesContainer> :
            <div />
        }
        <CaseWorkflowJalonText
          jalon="sousJalonPhotoApresTravaux"
          date={jalon.sousJalonPhotoApresTravaux.dateValidation || ''}
          state={jalon.sousJalonPhotoApresTravaux.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonAttestationTva.etat}
            small
          />
          Attestation de TVA
        </CaseWorkflowStyledComponents.Line>
        {
          jalon.sousJalonAttestationTva.attestationTva?.length && jalon.sousJalonAttestationTva.attestationTva.length > 0 ?
            <CaseWorkflowStyledComponents.PicturesContainer>
              {
                jalon.sousJalonAttestationTva.attestationTva.map((file, index) => (
                  <CaseWorkflowDownloadButton
                    key={`${file.url}-${index}`}
                    name={getCleanFilename(file.libelle || '', file.nomDuFichier || '')}
                    url={file.url || ''}
                  />
                ))
              }
            </CaseWorkflowStyledComponents.PicturesContainer> :
            <div />
        }
        <CaseWorkflowJalonText
          jalon="sousJalonAttestationTva"
          date={jalon.sousJalonAttestationTva.dateValidation || ''}
          state={jalon.sousJalonAttestationTva.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonBonDeCommande.etat}
            small
          />
          Bon de commande
        </CaseWorkflowStyledComponents.Line>
        {
          jalon.sousJalonBonDeCommande.bonDeCommande ?
            <CaseWorkflowDownloadButton
              name={getCleanFilename(jalon.sousJalonBonDeCommande.bonDeCommande.libelle || '', jalon.sousJalonBonDeCommande.bonDeCommande.nomDuFichier || '')}
              url={jalon.sousJalonBonDeCommande.bonDeCommande.url || ''}
            /> :
            <div />
        }
        <CaseWorkflowJalonText
          jalon="sousJalonBonDeCommande"
          date={jalon.sousJalonBonDeCommande.dateValidation || ''}
          state={jalon.sousJalonBonDeCommande.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonAttestationFinDeTravaux.etat}
            small
          />
          PV de fin de chantier
        </CaseWorkflowStyledComponents.Line>
        {
          jalon.sousJalonAttestationFinDeTravaux.attestationFinDeTravaux ?
            <CaseWorkflowDownloadButton
              name={getCleanFilename(jalon.sousJalonAttestationFinDeTravaux.attestationFinDeTravaux.libelle || '', jalon.sousJalonAttestationFinDeTravaux.attestationFinDeTravaux.nomDuFichier || '')}
              url={jalon.sousJalonAttestationFinDeTravaux.attestationFinDeTravaux.url || ''}
            /> :
            <div />
        }
        <CaseWorkflowJalonText
          jalon="sousJalonAttestationFinDeTravaux"
          date={jalon.sousJalonAttestationFinDeTravaux.dateValidation || ''}
          state={jalon.sousJalonAttestationFinDeTravaux.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowPostInterventionJalonCard
