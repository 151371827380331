/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import { getCleanFilename } from 'helpers/mediaUtils'
import { useIsReadOnly } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowDownloadButton from '../CaseWorflowButtons/CaseWorkflowDownloadButton'
import CaseWorkflowJalonText from '../CaseWorkflowJalonText'
import CaseWorkflowCancelMissionButton from '../CaseWorflowButtons/CaseWorkflowCancelMissionButton'

/* Type imports ------------------------------------------------------------- */
import type { JalonSimplifie } from 'API/__generated__/Api'
import {
  EtatFacture,
  WorkflowJalon,
  EtatDevis,
  WorkflowEtat,
} from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowJalonCardProps {
  jalon: JalonSimplifie;
  caseId: string;
  quoteState?: EtatDevis;
  invoiceState?: EtatFacture;
  isUrgentCase?: boolean;
  comment?: string;
}

const CaseWorkflowJalonCard: React.FC<CaseWorkflowJalonCardProps> = ({
  jalon,
  caseId,
  quoteState,
  invoiceState,
  isUrgentCase,
  comment,
}) => {
  const isReadOnly = useIsReadOnly()
  const navigate = useNavigate()

  const getButton = () => {
    switch (jalon.type.code) {
      case WorkflowJalon.AttestationTva:
        break
      case WorkflowJalon.Devis:
        if (quoteState) {
          if (quoteState === EtatDevis.Transmis || quoteState === EtatDevis.Retransmis) {
            return (
              <CaseWorkflowStyledComponents.Button
                variant="contained"
                onClick={() => navigate('devis')}
                disabled={isReadOnly}
              >
                Voir et accepter le Devis
              </CaseWorkflowStyledComponents.Button>
            )
          }
        }
        break
      case WorkflowJalon.Facture:
        if (invoiceState) {
          if (invoiceState === EtatFacture.Envoye) {
            return (
              <CaseWorkflowStyledComponents.Button
                variant="contained"
                onClick={() => navigate('facture')}
                disabled={isReadOnly}
              >
                Voir la facture
              </CaseWorkflowStyledComponents.Button>
            )
          }
        }
        break
      case WorkflowJalon.Creation:
        if (jalon.etat !== WorkflowEtat.Annule) {
          return (
            <CaseWorkflowCancelMissionButton
              caseId={caseId}
              isUrgentCase={isUrgentCase}
            />
          )
        }
        break
      default:
        break
    }
    if (jalon.pieceJointe) {
      return (
        <CaseWorkflowDownloadButton
          name={getCleanFilename(jalon.pieceJointe?.libelle || '', jalon.pieceJointe?.fileName || '')}
          url={jalon.pieceJointe?.url || ''}
        />
      )
    }
  }

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          {jalon.libelle ?? jalon.type.libelle}
        </CaseWorkflowStyledComponents.Title>
        {getButton() ?? <div />}
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.date}
          state={jalon.etat}
          quoteState={quoteState}
          invoiceState={invoiceState}
          comment={comment}
        />
      </CaseWorkflowStyledComponents.CardContent>
    </Card>
  )
}

export default CaseWorkflowJalonCard
